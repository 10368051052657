'use client';

import { AppAction } from '@/contexts/TokenContext/Roles';
import { useIsAllowed } from '@/contexts/TokenContext/useIsAllowed';
import React, { PropsWithChildren } from 'react';

export interface RestrictedContentParams extends PropsWithChildren {
    action?: AppAction;
    fallback?: JSX.Element | null;
}

// TODO: pass fallbackcontent in cases where we want users to get a specific message
export default function RestrictedContent(params: RestrictedContentParams): JSX.Element {
    const action = params?.action;

    const allowed = useIsAllowed(action);
    if (!allowed) {
        let fallback = params?.fallback;
        if (fallback === undefined) {
            fallback = null;
        }
        return fallback;
    }

    return <React.Fragment>{params?.children}</React.Fragment>;
}
