'use client';

import React, { useState } from 'react';
import { Button, NavDropdown, Offcanvas } from 'react-bootstrap';
import styles from './page.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import RestrictedContent from '@/app/(authenticated)/content/restricted/template';
import { AppAction } from '@/contexts/TokenContext/Roles';
import { usePathChanged } from '../../../hooks/usePathChanged';
import ConditionalLink from '@/app/(authenticated)/jobs/(jobId)/jobLink/ConditionalLink';
import MenuSummary from './summary/page';

function MenuContent(): JSX.Element {
    return (
        <section className={styles.menu}>
            <MenuSummary />

            <RestrictedContent action={AppAction.SUMMARY}>
                <ConditionalLink href="/summary/">Summary</ConditionalLink>
                <br />
            </RestrictedContent>

            <RestrictedContent action={AppAction.SEARCH_ORDERS}>
                <ConditionalLink href="/premises/">Premises</ConditionalLink>
                <br />
                <ConditionalLink href="/jobs/">Jobs</ConditionalLink>
                <br />
            </RestrictedContent>

            <RestrictedContent action={AppAction.CONFIG}>
                <br />
                <b>Config</b>
                <RestrictedContent action={AppAction.EDIT_COUNTS}>
                    <br />
                    <ConditionalLink href="/config/counts/">Counts</ConditionalLink>
                </RestrictedContent>
                <RestrictedContent action={AppAction.SWITCH_MODE}>
                    <br />
                    <ConditionalLink href="/config/modes/">Storm Mode</ConditionalLink>
                </RestrictedContent>
                <RestrictedContent action={AppAction.EDIT_MODES}>
                    <br />
                    <NavDropdown title="Messages">
                        <NavDropdown.ItemText>
                            <ConditionalLink href="/config/messages/estimates/">Estimate</ConditionalLink>
                        </NavDropdown.ItemText>
                        <NavDropdown.ItemText>
                            <ConditionalLink href="/config/messages/reports/">Reports</ConditionalLink>
                        </NavDropdown.ItemText>
                        <NavDropdown.ItemText>
                            <ConditionalLink href="/config/messages/status/">Status</ConditionalLink>
                        </NavDropdown.ItemText>
                        <NavDropdown.ItemText>
                            <ConditionalLink href="/config/messages/causeCodes/">Cause Code</ConditionalLink>
                        </NavDropdown.ItemText>
                        <NavDropdown.ItemText>
                            <ConditionalLink href="/config/messages/errors/">Errors</ConditionalLink>
                        </NavDropdown.ItemText>
                    </NavDropdown>
                </RestrictedContent>
                <RestrictedContent action={AppAction.EDIT_SETTINGS}>
                    <NavDropdown title="Settings">
                        <NavDropdown.ItemText>
                            <ConditionalLink href="/config/settings/estimates/">Estimates</ConditionalLink>
                        </NavDropdown.ItemText>
                        <NavDropdown.ItemText>
                            <ConditionalLink href="/config/settings/notifications/">Notifications</ConditionalLink>
                        </NavDropdown.ItemText>
                        <NavDropdown.ItemText>
                            <ConditionalLink href="/config/settings/notifications/powerRestored/">PRNs</ConditionalLink>
                        </NavDropdown.ItemText>
                        <NavDropdown.ItemText>
                            <ConditionalLink href="/config/settings/recentlyClosed/">Opening / Closing</ConditionalLink>
                        </NavDropdown.ItemText>
                        <NavDropdown.ItemText>
                            <ConditionalLink href="/config/settings/dataChecks/">Data Checks</ConditionalLink>
                        </NavDropdown.ItemText>
                    </NavDropdown>
                </RestrictedContent>
            </RestrictedContent>

            <hr />
            <b>Customer Links</b>
            <br />
            <ConditionalLink href="https://outage.dteenergy.com/">Outage Center</ConditionalLink>
            <br />
            <ConditionalLink href="https://outage.dteenergy.com/map">Outage Map</ConditionalLink>

            <RestrictedContent action={AppAction.EDIT_MODES}>
                <br />
                <br />
                <b>Internal Links</b>
                <br />
                <ConditionalLink href="https://app.sos.sites.dteenergy.com/">
                    System Outage Screen (SOS)
                </ConditionalLink>
                <br />
                <ConditionalLink href="https://kubra.io/stormcenter/views/ca940bc4-81c4-4d22-a2b0-dd338f5a9a32">
                    Outage Map
                </ConditionalLink>
            </RestrictedContent>
        </section>
    );
}

export default function MenuButton(): JSX.Element {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // Close this when changing paths
    usePathChanged(handleClose);

    // Clearing flyout when no longer visible -- otherwise it kept stealing focus
    let flyout = null;
    if (show) {
        flyout = (
            <Offcanvas className={styles.flyout} show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Menu</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <MenuContent />
                </Offcanvas.Body>
            </Offcanvas>
        );
    }

    return (
        <section className={styles.main}>
            <Button onClick={handleShow} title="Menu">
                <FontAwesomeIcon icon={faBars} className="menuIcon" size={'xl'} />
            </Button>

            {flyout}
        </section>
    );
}
